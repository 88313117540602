<template>
  <div class="container">
    <Loading :loading="loading" />
    <template v-if="!loading">
      <div class="content" v-html="content"></div>
    </template>
  </div>
</template>


<script>
import { fetchDetail } from "@/api/news";
export default {
  data() {
    return {
      detail: {},
      loading: false,
    };
  },

  watch: {
    $route() {
      const { query } = this.$route;
      const { code } = query || {};
      if (code) {
        this.getDetail(code);
      }
    },
  },

  computed: {
    content() {
      let that = this;
      return this.detail.content
        ? this.L(this.detail.content).replace(
            /<video [^>]*src=['"]([^'"]+)[^>]*>/g,
            function (match, capture) {
              return `<video muted controls autoplay style='width: 100%' src='${that.L2(capture)}'>`;
            }
          )
        : "";
    },
  },

  mounted() {
    const { query } = this.$route;
    const { code } = query || {};
    if (code) {
      this.getDetail(code);
    }
  },
  methods: {
    getDetail(code) {
      this.loading = true;
      fetchDetail({
        code,
      })
        .then((res) => {
          this.detail = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>


<style lang="less" scoped>
.title {
  font-size: 30px;
  line-height: 1em;
  font-weight: 600;
  color: #000000;
  opacity: 0.8;
  text-align: center;
  padding-top: 67px;
}
.author {
  font-size: 12px;
  font-weight: 300;
  color: #000000;
  opacity: 0.8;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.author > span:first-child {
  padding-right: 24px;
}
p {
  font-weight: 400;
  line-height: 45px;
  color: #6a6a6a;
}
p:last-child {
  padding-bottom: 100px;
}

.image {
  display: block;
  width: 100%;
}

.content {
  width: 1200px;
  min-height: 600px;
  margin-bottom: 24px;
  padding-top: 24px;
}

@media screen and (max-width: 767px) {
  .content {
    width: 100%;
    min-height: 600px;
    margin-bottom: 24px;
  }
}
</style>